import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import api from '../../lib/axios';
import axios from 'axios';

const AddBonus = () => {
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [bonusTypeId, setBonusTypeId] = useState('');
  const [bonusTypes, setBonusTypes] = useState([]);

  const getBonusTypes = async () => {
    try {
      const response = await api.get('/api/bonus-types');
      setBonusTypes(response.data);
    } catch (error) {
      alert('Error fetching bonus types.');
    }
  };

  useEffect(() => {
    getBonusTypes();
  }, []);

  // Function to get client extra details
  const getClientExtraDetails = async () => {
    // Get current date
    const currentDate = new Date();

    // Format the date as "dd-MM-yy"
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    };

    // Set EndDateLocal to current date
    const endDateLocal = formatDate(currentDate);

    // Set StartDateLocal to 7 days before the current date
    const startDateLocal = formatDate(
      new Date(currentDate.setDate(currentDate.getDate() - 7))
    );
    const userId = 60359177;
    const url =
      'https://backofficewebadmin.betconstruct.com/api/en/Client/GetClientBonuses';
    const headers = {
      Accept: 'application/json, text/plain, */*',
      Authentication:
        'a84c87cff5d32d3fe37a885a31a8b521ac3f674aa57a12cd18852a1141751be0',
      Priority: 'u=1, i',
    };
    const requestData = {
      AcceptanceType: null,
      BonusType: null,
      ClientBonusId: '',
      ClientId: `${userId}`,
      EndDateLocal: `${endDateLocal} - 00:00:00`,
      PartnerBonusId: '',
      PartnerExternalBonusId: '',
      StartDateLocal: `${startDateLocal} - 00:00:00`,
    };

    axios
      .post(url, requestData, { headers })
      .then((response) => {
        const bonusArray = response.data.Data.map(
          ({ CreatedLocal, Name, Amount }) => ({
            CreatedLocal: CreatedLocal,
            Name: Name,
            Amount: Amount,
          })
        );
        console.log(bonusArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const tryLogin = async () => {
    const loginData = {
      email: 'leonardoelitgold@gmail.com',
      password: 'Kaan@it123',
      domain: 'www.accounts-bc.com',
      returnUrl:
        'https://api.accounts-bc.com/connect/authorize?client_id=BackOfficeSSO&response_type=code%20token%20id_token&scope=openid%20profile%20email%20offline_access%20introspect.full.access%20real_ip&redirect_uri=https%3A%2F%2Fbackofficewebadmin.betconstruct.com%2Fapi%2Fen%2Faccount%2Fssocallback&state=https%3A%2F%2Fbackoffice.betconstruct.com%2F&nonce=https%3A%2F%2Fbackofficewebadmin.betconstruct.com&response_mode=form_post',
    };

    try {
      const response = await axios.post(
        'https://api.accounts-bc.com/v1/auth/login',
        loginData,
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Priority: 'u=1, i',
          },
          withCredentials: true, // Ensure cookies are included in requests
          maxRedirects: 0, // Prevent automatic redirects to capture headers
        }
      );

      console.log(response.headers);
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const tryFromFrontend = async () => {
    const url =
      'https://api.accounts-bc.com/connect/authorize?client_id=BackOfficeSSO&response_type=code%20token%20id_token&scope=openid%20profile%20email%20offline_access%20introspect.full.access%20real_ip&redirect_uri=https%3A%2F%2Fbackofficewebadmin.betconstruct.com%2Fapi%2Fen%2Faccount%2Fssocallback&state=https%3A%2F%2Fbackoffice.betconstruct.com%2F&nonce=https%3A%2F%2Fbackofficewebadmin.betconstruct.com&response_mode=form_post';

    const aspNetCoreIdentity =
      '.AspNetCore.Identity.Application=CfDJ8MXvTUCD4M5NhmzcqkekGLNDrO97NicP2wZBZ1mDVE7iSOGKOqjqSKkgUj-UJ5Cs2IwyHbRWgo9TrMbaF5D0DWHjKRf0msYoRPRhdjNdwCW0NNuL5Y9s20j7kvaFdE92ImPGB43tRmnatHRkv7VhNt7jtEAVJJ1n_f0aux7fJJQH8eREqEIBXOCuUDHfy2YRESphh5uHVOa-DGliZAiv_Cir0mypOAlG6O9mVAV2_bRCEVq-LiUJS0zwjPrLm2ummqdQlkyvXZWX9SlpDvHVCmrv9OjGUIcx5iMoRpIJJfg3Vvb3F_YmVszzr8VFwv31kxUZHCwFDZpGVk3VGKcdJXEItVqLI3MuTSDweP374zHyTE0sNqOuq0ZZFFpK6otIMBzkbxYeXTK5OQznGBGZnb9fC2mBKfuzGhR0R-mZjEsjPQ3PIRhlRHH2zsoQmawFIy18h2MYqxJmQkwoidJkO7MvUjEi24lHovbuY5Nl1YBHmrxJEgSVEFqQFd5gViZa2VdRSZh_5vqaM70-SQ0eSFfBT68QDtIEgkNH4HYzTtbBZ5ZDLoSjUq8ziPoPF3KnT19W6K4RqfSfh_oOxUt6Ir8nJXoMbMIwMFZ03mlGuGXP0jzSF2l2TWohRhHoLe_IrTWXDX0ntnUOT6-gsCGqXqBjOIqw7NYvaRxZGs8V7y9Xh9PSzd3NTsUL9M5X6-_jNq_ThpGGelqSn4HsfeT_PchDeBi2sDRj-JqYnzDBkjRid7b2d-b0KCTlGw-TeOIr-_jbrrTTYPA7XgiLED9JIKwma_ASyy9L5qFy5_G5D-gcb42qW4Jv-6N9F9IaBiYWuS6WPUnwVO78Jr74iJ0xR7HE5EhLGY7zfEKFL-Vpmssiz4RDt1K9fxW5Uw8CeMDLwUPgwMISPDJcgqHwLnEKusg1wiPhAEZNahfDnDOBY4TV6JT_IO5N7vYSXnUSU_Jc6cloCG5iamTAmIaTvtOfxC-E4RrjuDKGeO_Pu_DeFyNj1G5lu_IktsQrOL4bSUE4fMqY7trUyZZSH85u3SjLOl0d9ko1nKgmisQtzKafPaY87huGMd1AMOs4G_fm2L-mzj5HwwWd_5rymGr8gBEH2Lk';

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Cookie:
          '.AspNetCore.Identity.Application=CfDJ8MXvTUCD4M5NhmzcqkekGLNDrO97NicP2wZBZ1mDVE7iSOGKOqjqSKkgUj-UJ5Cs2IwyHbRWgo9TrMbaF5D0DWHjKRf0msYoRPRhdjNdwCW0NNuL5Y9s20j7kvaFdE92ImPGB43tRmnatHRkv7VhNt7jtEAVJJ1n_f0aux7fJJQH8eREqEIBXOCuUDHfy2YRESphh5uHVOa-DGliZAiv_Cir0mypOAlG6O9mVAV2_bRCEVq-LiUJS0zwjPrLm2ummqdQlkyvXZWX9SlpDvHVCmrv9OjGUIcx5iMoRpIJJfg3Vvb3F_YmVszzr8VFwv31kxUZHCwFDZpGVk3VGKcdJXEItVqLI3MuTSDweP374zHyTE0sNqOuq0ZZFFpK6otIMBzkbxYeXTK5OQznGBGZnb9fC2mBKfuzGhR0R-mZjEsjPQ3PIRhlRHH2zsoQmawFIy18h2MYqxJmQkwoidJkO7MvUjEi24lHovbuY5Nl1YBHmrxJEgSVEFqQFd5gViZa2VdRSZh_5vqaM70-SQ0eSFfBT68QDtIEgkNH4HYzTtbBZ5ZDLoSjUq8ziPoPF3KnT19W6K4RqfSfh_oOxUt6Ir8nJXoMbMIwMFZ03mlGuGXP0jzSF2l2TWohRhHoLe_IrTWXDX0ntnUOT6-gsCGqXqBjOIqw7NYvaRxZGs8V7y9Xh9PSzd3NTsUL9M5X6-_jNq_ThpGGelqSn4HsfeT_PchDeBi2sDRj-JqYnzDBkjRid7b2d-b0KCTlGw-TeOIr-_jbrrTTYPA7XgiLED9JIKwma_ASyy9L5qFy5_G5D-gcb42qW4Jv-6N9F9IaBiYWuS6WPUnwVO78Jr74iJ0xR7HE5EhLGY7zfEKFL-Vpmssiz4RDt1K9fxW5Uw8CeMDLwUPgwMISPDJcgqHwLnEKusg1wiPhAEZNahfDnDOBY4TV6JT_IO5N7vYSXnUSU_Jc6cloCG5iamTAmIaTvtOfxC-E4RrjuDKGeO_Pu_DeFyNj1G5lu_IktsQrOL4bSUE4fMqY7trUyZZSH85u3SjLOl0d9ko1nKgmisQtzKafPaY87huGMd1AMOs4G_fm2L-mzj5HwwWd_5rymGr8gBEH2Lk',
      },
      withCredentials: true,
      maxRedirects: 0,
    };

    await axios.get(url, { headers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (userId && bonusTypeId) {
      try {
        await api.post(
          '/api/request-bonus',
          {
            userId,
            username,
            bonusTypeId,
          },
          {
            headers: {
              Authorization: token, // Add token to headers
            },
          }
        );
        setUserId('');
        setUsername('');
        setBonusTypeId('');
        alert('Bonus request submitted successfully!');
      } catch (error) {
        console.log(error);
        if (error.response) {
          alert(
            `${
              error.response.message
                ? error.response.message
                : 'An error occured. Token could be outdated.'
            }`
          );
        } else {
          alert('An error occurred. Please try again.');
        }
      }
    } else {
      alert('Please fill in all fields');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p={2}
    >
      <Typography variant="h4" gutterBottom>
        Add Bonus Request
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 400 }}>
        <TextField
          label="User ID"
          variant="outlined"
          margin="normal"
          fullWidth
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Bonus Type</InputLabel>
          <Select
            value={bonusTypeId}
            onChange={(e) => setBonusTypeId(e.target.value)}
          >
            {bonusTypes.map((bonus) => (
              <MenuItem key={bonus.id} value={bonus.id}>
                {bonus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '10px',
            border: `1px solid #727681`,
          }}
        >
          Send Request
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '10px',
            border: `1px solid #727681`,
          }}
          onClick={getClientExtraDetails}
        >
          BONUSES
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '10px',
            border: `1px solid #727681`,
          }}
          onClick={tryFromFrontend}
        >
          tryFromFrontend
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '10px',
            border: `1px solid #727681`,
          }}
          onClick={tryLogin}
        >
          TRY LOGIN
        </Button>
      </form>
    </Box>
  );
};

export default AddBonus;
