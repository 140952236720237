//bonup-app/frontend/src/scenes/dashboard
import React, { useEffect, useState, useCallback } from 'react';
// Mui Components
import {
  Box,
  Button,
  Stack,
  useTheme,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import InfoIcon from '@mui/icons-material/Info';
// API and Functions
import api from '../../lib/axios';
import { verifyToken } from '../../lib/axios';
import { io } from 'socket.io-client';
import moment from 'moment';
//Auth
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
// Alert
import { useAlert } from '../../lib/useAlert';
import ClientDetailsCard from '../../components/ClientDetailsCard';
import { wheelPrizes } from '../../constants';

const socket = io(`${process.env.REACT_APP_API_URL}`);

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [requests, setRequests] = useState([]);
  const [openAccept, setOpenAccept] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedBonusType, setSelectedBonusType] = useState(String);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectOrText, setSelectOrText] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const token = localStorage.getItem('token');
  const employee = localStorage.getItem('employee');
  const { handleLogout } = useAuth();
  const showAlert = useAlert();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.3,
      disableClickEventBubbling: true,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      flex: 0.6,
      renderCell: ({ row: { userId } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Link
              to={`https://backoffice.betconstruct.com/#/customers/detail/${userId}`}
              style={{ textDecoration: 'none', color: '#4caf50' }}
              target="_blank"
            >
              <Typography>{userId}</Typography>
            </Link>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'bonusType',
      headerName: 'Bonus Type',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'LastDepositAmount',
      headerName: 'Deposit Amount',
      cellClassName: 'name-column--cell',
      flex: 1,
      renderCell: ({ row: { LastDepositAmount } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            marginLeft={1.5}
          >
            <Typography>{LastDepositAmount}</Typography>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'LastDepositTime',
      headerName: 'Deposit Time',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'LastWithdrawalTime',
      headerName: 'Withdrawal Time',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'lastTimeUserTakeBonus',
      headerName: 'Last Time Bonus Take',
      flex: 1.1,
      renderCell: ({ row: { lastTimeUserTakeBonus, updatedAt } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            {lastTimeUserTakeBonus === 'Invalid date' ? (
              <Typography>{updatedAt}</Typography>
            ) : (
              <Typography>{lastTimeUserTakeBonus}</Typography>
            )}
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: ({ row: { status } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              p={0.8}
              paddingX={2}
              display="flex"
              justifyContent="center"
              backgroundColor={
                status === 'accepted'
                  ? colors.greenAccent[600]
                  : status === 'rejected'
                  ? '#f44336'
                  : colors.grey[700]
              }
              borderRadius="4px"
            >
              {status === 'accepted' && <CheckIcon />}
              {status === 'rejected' && <CloseIcon />}
              {status === 'pending' && <AccessTimeIcon />}
              <Typography
                style={{ textTransform: 'capitalize' }}
                color={colors.grey[100]}
                sx={{ ml: '5px' }}
              >
                {status}
              </Typography>
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.3,
      renderCell: ({ row: { id, bonusType, status } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              alignContent="center"
              width={1}
            >
              <Button
                variant="outlined"
                color="success"
                size="small"
                disabled={status !== 'pending'}
                onClick={() => handleClickOpen(id, bonusType)}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="small"
                disabled={status !== 'pending'}
                onClick={() => handleReject(id)}
              >
                Reject
              </Button>
            </Stack>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'block',
      headerName: 'Block',
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#7a0012',
              },
            }}
            onClick={() => handleBlockOpen(id)}
          >
            <BlockIcon />
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'info',
      headerName: 'Info',
      flex: 0.3,
      renderCell: ({ row: { userId, username } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#4BB543',
              },
            }}
            onClick={() => handleInfoOpen(userId, username)}
          >
            <InfoIcon />
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];

  const handleClickOpen = (id, bonusType) => {
    setSelectedRequestId(id);
    setSelectedBonusType(bonusType);
    setOpenAccept(true);
  };

  const handleBlockOpen = (id) => {
    setSelectedRequestId(id);
    setSelectedBlock(true);
    setOpenAccept(true);
  };

  const handleInfoOpen = (userId, username) => {
    setSelectedUserId(userId);
    setSelectedUsername(username);
    setInfoOpen(!infoOpen);
  };

  const handleClose = () => {
    setOpenAccept(false);
    setInputValue('');
    setSelectedBlock(false);
  };

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  // Handle requests in our server
  const handleAccept = async () => {
    if (selectedRequestId) {
      const isTokenValid = await verifyToken(token);
      if (!isTokenValid) {
        handleLogout();
        return;
      }
      if (selectedBlock) {
        try {
          await api.post(
            '/api/reject-request',
            { requestId: selectedRequestId, employeeName: employee },
            {
              headers: {
                Authorization: token,
              },
            }
          );
        } catch (error) {
          console.error('Error in blocked reject:', error);
        }
        try {
          const response = await api.post(
            '/api/block-user',
            {
              requestId: selectedRequestId,
              employeeName: employee,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          );
          handleAlert(response.data.message);
          fetchRequests();
          handleClose();
        } catch (error) {
          console.error('Error blocking player:', error);
        }
      } else {
        try {
          const response = await api.post(
            '/api/accept-request',
            {
              requestId: selectedRequestId,
              employeeName: employee,
              value: inputValue,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          );
          handleAlert(response.data.message);
          fetchRequests();
          handleClose();
        } catch (error) {
          console.error('Error in handleAccept:', error);
        }
      }
    }
  };
  // Handle reject request
  const handleReject = async (requestId) => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    try {
      const response = await api.post(
        '/api/reject-request',
        { requestId, employeeName: employee },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert(response.data.message);
      fetchRequests();
    } catch (error) {
      console.error('Error in handleReject:', error);
    }
  }; // Format Date Function
  const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');
  // Fetch bonus requests from our server
  const fetchRequests = useCallback(async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    try {
      const response = await api.get('/api/pending-requests', {
        headers: {
          Authorization: token,
        },
      });
      const formattedRequests = response.data.map((request) => ({
        ...request,
        LastDepositTime: formatDate(request.LastDepositTime),
        LastWithdrawalTime: formatDate(request.LastWithdrawalTime),
        lastTimeUserTakeBonus: formatDate(request.lastTimeUserTakeBonus),
        createdAt: formatDate(request.createdAt),
        updatedAt: formatDate(request.updatedAt),
      }));
      setRequests(formattedRequests);
    } catch (error) {
      console.error('Error in fetchRequests:', error);
    }
  }, [token]);
  useEffect(() => {
    fetchRequests();

    socket.on('newRequest', (newRequest) => {
      fetchRequests();
    });

    socket.on('updateRequest', (updatedRequest) => {
      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === updatedRequest.id
            ? { ...request, status: updatedRequest.status }
            : request
        )
      );
    });

    return () => {
      socket.off('newRequest');
      socket.off('updateRequest');
    };
  }, [fetchRequests]);

  return (
    <Box m="20px">
      <Header
        title="DASHBOARD"
        subtitle="Manage bonus requests"
        color={colors.greenAccent[400]}
        mt={0}
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
          }}
          rows={requests}
          columns={columns}
        />
      </Box>
      <Dialog open={openAccept} onClose={handleClose}>
        <DialogTitle>
          {selectedBonusType === 'Çark Kodu Talep'
            ? 'Enter Wheel Code'
            : selectedBonusType === 'Slot Alanı Deneme Bonusu'
            ? 'Are you sure to send the bonus ?'
            : selectedBlock === true
            ? 'Are you sure to block the player ?'
            : 'Enter Amount for Bonus'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedBonusType === 'Çark Kodu Talep'
              ? 'Enter the code for the wheel code request'
              : selectedBonusType === 'Slot Alanı Deneme Bonusu'
              ? 'Click confirm to send the "Slot Alanı Deneme Bonusu"'
              : selectedBlock === true
              ? 'Click block to block player'
              : 'Enter the amount for the bonus'}
          </DialogContentText>
          {selectedBonusType === 'Slot Alanı Deneme Bonusu' ? (
            ''
          ) : selectedBlock === true ? (
            ''
          ) : selectedBonusType === 'Çark Kodu Talep' ? (
            <Box display="flex" flexDirection="column">
              {selectOrText ? (
                <TextField
                  autoFocus
                  label="Custom Prize Value"
                  type="text"
                  variant="standard"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Select Prize</InputLabel>
                  <Select
                    value={inputValue} // State to store the selected prize
                    onChange={(e) => setInputValue(e.target.value)} // Function to update the selected prize
                    label="Select Prize"
                  >
                    {wheelPrizes.map((prize) => (
                      <MenuItem key={prize.id} value={prize.id}>
                        {prize.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectOrText}
                    onChange={(e) => setSelectOrText(e.target.checked)} // Toggle between Select and TextField
                  />
                }
                label="Use custom value"
              />
            </Box>
          ) : (
            <TextField
              autoFocus
              margin="dense"
              label="Value"
              type="text"
              fullWidth
              variant="standard"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="inherit">
            {selectedBonusType === 'Slot Alanı Deneme Bonusu'
              ? 'Confirm'
              : selectedBlock === true
              ? 'Block'
              : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      {infoOpen && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '44rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <ClientDetailsCard
            infoClose={setInfoOpen}
            newUserId={selectedUserId}
            newUsername={selectedUsername}
          />
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
