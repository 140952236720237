import { useEffect, useState } from 'react';
import { ColorModeContext, useMode } from './theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebarr from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import AddBonus from './scenes/addBonus';
import BonusHistory from './scenes/BonusHistory';
import Login from './scenes/login';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from './context/AuthContext';
import { AlertProvider } from './lib/useAlert';
import BlockedUsers from './scenes/BlockedUsers';
import ManageEmployees from './scenes/ManageEmployees';
import BonusTypes from './scenes/BonusTypes';

function App() {
  const [theme, colorMode] = useMode();
  const { isAuthenticated, handleLogout } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading state handled by AuthProvider
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertProvider>
          <div className="app">
            {isAuthenticated && <Sidebarr />}
            <main className="content">
              {isAuthenticated && <Topbar handleLogout={handleLogout} />}
              <Routes>
                <Route path="/login" element={<Login />} />
                {isAuthenticated ? (
                  <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/addBonus" element={<AddBonus />} />
                    <Route path="/bonusHistory" element={<BonusHistory />} />
                    <Route path="/blocked-users" element={<BlockedUsers />} />
                    <Route path="/bonus-types" element={<BonusTypes />} />
                    <Route
                      path="/manage-employees"
                      element={<ManageEmployees />}
                    />
                  </>
                ) : (
                  <Route path="*" element={<Navigate to="/login" replace />} />
                )}
              </Routes>
            </main>
          </div>
        </AlertProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
