import React from 'react';
import { useState } from 'react';
import api from '../lib/axios';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';

const BlockUserForm = ({ reloadFunction, closeBlockUser }) => {
  const token = localStorage.getItem('token');
  const employee = localStorage.getItem('employee');
  const showAlert = useAlert();
  const [username, setUsername] = useState('');

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
    reloadFunction();
  };
  // Handle Close
  const handleClose = () => {
    closeBlockUser(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(
        '/api/blockUserByName',
        {
          username: username,
          employeeName: employee,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert('User blocked successfully successfully!');
    } catch (err) {
      handleAlert(
        `Error blocking user ${username}, please check username and try again.`
      );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Block User By Username</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
        >
          Block
        </Button>
      </form>
    </Box>
  );
};

export default BlockUserForm;
