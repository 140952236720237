import { useEffect, useState } from 'react';
import api from '../lib/axios';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';

const ClientDetailsCard = ({ infoClose, newUserId, newUsername }) => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [clientDetailList, setClientDetailList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  const showAlert = useAlert();

  useEffect(() => {
    if (newUserId === selectedUserId) {
      return;
    } else {
      setSelectedUserId(newUserId);
    }
    getClientArrays(newUserId);
  }, [selectedUserId, newUserId]);

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  //Get client arrays
  const getClientArrays = async (userId) => {
    setIsLoading(true);
    try {
      const response = await api.post(
        '/api/users/GetUserDetailsById',
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setClientDetailList(response.data.finalClientDetailArray);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleAlert(error.response.data.message);
    }
  };

  const handleClose = () => {
    infoClose(false);
  };

  return (
    <Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography>LOADING...</Typography>
        </Box>
      ) : clientDetailList.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="relative"
          padding={3}
        >
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography>There is no data to show.</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="relative"
          padding={1}
        >
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" margin gutterBottom>
            <Box component="span" sx={{ color: '#45a049' }}>
              {newUsername}
            </Box>{' '}
            's Transaction and Bonus Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientDetailList.map((row) => (
                  <TableRow key={row.CreatedLocal}>
                    <TableCell component="th" scope="row">
                      {row.CreatedLocal}
                    </TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.Amount}</TableCell>
                    <TableCell>{row.Note}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ClientDetailsCard;
