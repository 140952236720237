import { useState } from 'react';
import api from '../lib/axios';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';
import { useAuth } from '../context/AuthContext';

const OtpForm = ({
  is2faSecret,
  closeOtpWindow,
  employeeUsername,
  qrCodeURL,
  employeeTempToken,
}) => {
  const [otpToken, setOtpToken] = useState('');
  const showAlert = useAlert();
  const { handleLogin } = useAuth();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };
  // Handle Close
  const handleClose = () => {
    closeOtpWindow(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(
        '/api/auth/verify-2fa',
        {
          otpToken,
          username: employeeUsername,
        },
        {
          headers: {
            Authorization: `${employeeTempToken}`,
          },
        }
      );
      handleLogin(response.data);
    } catch (err) {
      handleAlert('Wrong otp code, please try again.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Google Auth 2FA OTP</Typography>
      <form onSubmit={handleSubmit}>
        {!is2faSecret && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="5px"
            paddingTop="10px"
          >
            <img src={qrCodeURL} alt="qr-code" />
            <Typography
              variant="subtitle2"
              paddingTop="20px"
              paddingBottom="0px"
            >
              Scan this code in Google Authenticator app
            </Typography>
          </Box>
        )}
        <TextField
          label="OTP CODE"
          variant="outlined"
          margin="normal"
          fullWidth
          value={otpToken}
          onChange={(e) => setOtpToken(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
        >
          Verify
        </Button>
      </form>
    </Box>
  );
};

export default OtpForm;
