import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import api from '../../lib/axios';
import { useAuth } from '../../context/AuthContext';
import { useAlert } from '../../lib/useAlert';
import { verifyToken } from '../../lib/axios';
import BonusTypeForm from '../../components/BonusTypeForm';
import BonusTypeChangeForm from '../../components/BonusTypeChangeForm';

const BonusTypes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    {
      field: 'name',
      headerName: 'Bonus Name',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.3,
      renderCell: ({ row: { category } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            {category === 'casino'
              ? 'Casino'
              : category === 'spor'
              ? 'Spor'
              : 'Diger'}
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'defId',
      headerName: 'Def Id',
      flex: 0.3,
      disableClickEventBubbling: true,
    },
    {
      field: 'imageUrl',
      headerName: 'Image URL',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      renderCell: ({
        row: { id, name, category, defId, type, imageUrl, note },
      }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: colors.primary[700],
                },
                padding: '10px',
              }}
              onClick={() =>
                handleClickChangeBonusType(
                  id,
                  name,
                  category,
                  defId,
                  type,
                  imageUrl,
                  note
                )
              }
            >
              <EditIcon />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: '#7a0012',
                },
              }}
              onClick={() => handleAreYouSure(id)}
            >
              <DeleteOutlineIcon />
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [bonusTypes, setBonusTypes] = useState([]);
  const [showBonusTypeFrom, setShowBonusTypeForm] = useState(false);
  const [showBonusTypeChangeFrom, setShowBonusTypeChangeForm] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [id, setId] = useState(Number);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('spor' || 'casino' || 'diger');
  const [defId, setDefId] = useState(Number);
  const [type, setType] = useState('2', '5', '0');
  const [imageURL, setImageURL] = useState('');
  const [note, setNote] = useState('');
  const { handleLogout, isAdmin } = useAuth();
  const showAlert = useAlert();

  const fetchBonusTypes = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    const response = await api.get('/api/bonus-types');
    setBonusTypes(response.data);
  };

  useEffect(() => {
    fetchBonusTypes();
  }, []);

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  // Handle click change bonus type
  const handleClickChangeBonusType = async (
    id,
    name,
    category,
    defId,
    type,
    imageUrl,
    note
  ) => {
    setId(id);
    setName(name);
    setCategory(category);
    setDefId(defId);
    setType(type);
    setImageURL(imageUrl);
    setNote(note);
    setShowBonusTypeChangeForm(!showBonusTypeChangeFrom);
  };
  // Delete bonus type
  const deleteBonusType = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    try {
      const response = await api.post(
        '/api/delete-bonustype',
        { id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAreYouSure(false);
      handleAlert(response.data.message);
      fetchBonusTypes();
    } catch (error) {
      console.error('Error in handleReject:', error);
      handleAlert('Error deleting bonus type, please try again.');
    }
  };

  const handleClickOpen = () => {
    setShowBonusTypeForm(!showBonusTypeFrom);
  };
  const handleAreYouSure = (id) => {
    setId(id);
    setAreYouSure(true);
  };

  const handleAreYouSureClose = () => {
    setAreYouSure(false);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Bonus Types"
          subtitle="Manage Bonus Types"
          color={colors.greenAccent[400]}
          mt={0}
        />
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            width: 35,
            height: 35,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleClickOpen()}
        >
          {showBonusTypeFrom ? (
            <RemoveIcon sx={{ color: '#6870fa' }} />
          ) : (
            <AddIcon sx={{ color: '#6870fa' }} />
          )}
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          rows={bonusTypes}
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>
      {showBonusTypeFrom && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '25rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <BonusTypeForm
            reloadFunction={fetchBonusTypes}
            showBonusType={setShowBonusTypeForm}
          />
        </Box>
      )}
      {showBonusTypeChangeFrom && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '25rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <BonusTypeChangeForm
            reloadFunction={fetchBonusTypes}
            showBonusType={setShowBonusTypeChangeForm}
            name={name}
            category={category}
            defId={defId}
            type={type}
            imageURL={imageURL}
            note={note}
            id={id}
          />
        </Box>
      )}
      <Dialog open={areYouSure} onClose={handleAreYouSureClose}>
        <DialogTitle>Are you sure to delete this bonus type ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bonus Type will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAreYouSureClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={deleteBonusType} color="inherit">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BonusTypes;
