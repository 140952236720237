import { useState } from 'react';
import api from '../lib/axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';

const Register = ({ reloadFunction, closeRegister }) => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('employee');
  const token = localStorage.getItem('token');
  const showAlert = useAlert();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
    reloadFunction();
  };
  // Handle Close
  const handleClose = () => {
    closeRegister(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(
        '/api/auth/register',
        {
          username,
          name,
          role,
          password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert('Employee created successfully!');
    } catch (err) {
      handleAlert(`Error registering ${role}, please try again.`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Register</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {/* Role Select */}
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Role"
          >
            <MenuItem value="employee">Employee</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
        >
          Register
        </Button>
      </form>
    </Box>
  );
};

export default Register;
