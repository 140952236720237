import { useState } from 'react';
import api from '../lib/axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';
import { bonusTypes } from '../constants';

const BonusTypeForm = ({ reloadFunction, showBonusType }) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('spor' || 'casino' || 'diger');
  const [defId, setDefId] = useState('');
  const [type, setType] = useState('2' || '5' || '0');
  const [imageURL, setImageURL] = useState('');
  const [note, setNote] = useState('');
  const token = localStorage.getItem('token');
  const showAlert = useAlert();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
    showBonusType(false);
    reloadFunction();
  };
  // Handle Close
  const handleClose = () => {
    showBonusType(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(
        '/api/add-new-bonus-type',
        {
          name: name,
          category: category,
          defId: defId,
          type: type,
          imageUrl: imageURL,
          note: note,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert('New bonus type created successfully!');
    } catch (err) {
      handleAlert(`Error creating bonus type, please try again.`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Add Bonus Type</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Type</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Role"
          >
            {bonusTypes.map((bonusType) => (
              <MenuItem key={bonusType.id} value={bonusType.id}>
                {bonusType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Bonus Category</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Role"
          >
            <MenuItem value="spor">Spor</MenuItem>
            <MenuItem value="casino">Casino</MenuItem>
            <MenuItem value="diger">Diger</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Bonus Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Bonus Definition ID"
          variant="outlined"
          margin="normal"
          fullWidth
          value={defId}
          onChange={(e) => setDefId(e.target.value)}
        />
        <TextField
          label="Image URL"
          variant="outlined"
          margin="normal"
          fullWidth
          value={imageURL}
          onChange={(e) => setImageURL(e.target.value)}
        />
        <TextField
          label="Bonus Note"
          variant="outlined"
          multiline
          rows={4}
          sx={{
            '& .MuiInputBase-root': {
              height: 'auto',
            },
          }}
          margin="normal"
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default BonusTypeForm;
