// src/api.js
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

// Create an Axios instance
const api = axios.create({
  baseURL: baseUrl, // Replace with your API base URL
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Only log out if the request was made to /api/auth/
      if (error.config.url.startsWith('/api/auth/')) {
        // Token is expired or invalid, logout the user
        localStorage.removeItem('token');
        // Use window.location to force reload and navigate to login
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;

export const verifyToken = async (token) => {
  try {
    await api.get('/api/auth/verify-token', {
      headers: {
        Authorization: token,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
