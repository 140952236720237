export const bonusCategories = ['spor', 'casino', 'diger'];
export const bonusTypes = [
  { name: 'Freespin', id: '5' },
  { name: 'Wagering Bonus', id: '2' },
  { name: 'Freebet', id: '0' },
];
export const wheelPrizes = [
  { id: 1, label: '100 TL Nakit' },
  { id: 2, label: 'Kazanamadın' },
  { id: 3, label: '15 TL Nakit' },
  { id: 4, label: '50 Freespin' },
  { id: 5, label: '75 Freespin' },
  { id: 6, label: 'Kazanamadın' },
  { id: 7, label: '100 Freespin' },
  { id: 8, label: '75 TL Nakit' },
  { id: 9, label: '50 TL Nakit' },
  { id: 10, label: '50 Freebet' },
  { id: 11, label: '10 Freespin' },
  { id: 12, label: '100 Freebet' },
  { id: 13, label: '20 Freespin' },
  { id: 14, label: '10 TL Nakit' },
  { id: 15, label: '200 Freespin' },
];
