import { useState } from 'react';
import api from '../lib/axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';
import { bonusTypes } from '../constants';

const BonusTypeChangeForm = ({
  reloadFunction,
  showBonusType,
  name,
  category,
  defId,
  type,
  imageURL,
  note,
  id,
}) => {
  const [changedName, setChangedName] = useState(name);
  const [changedCategory, setChangedCategory] = useState(category);
  const [changedDefId, setChangedDefId] = useState(defId);
  const [changedType, setChangedType] = useState(type);
  const [changedImageURL, setChangedImageURL] = useState(imageURL);
  const [changedNote, setChangedNote] = useState(note);
  const token = localStorage.getItem('token');
  const showAlert = useAlert();

  const defaultBonusType = bonusTypes.find(
    (bonusType) => bonusType.id === type
  )?.name;

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
    showBonusType(false);
    reloadFunction();
  };
  // Handle Close
  const handleClose = () => {
    showBonusType(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(
        '/api/change-bonus-type',
        {
          name: changedName,
          category: changedCategory,
          defId: changedDefId,
          type: changedType,
          imageUrl: changedImageURL,
          note: changedNote,
          id: id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert('Bonus type changed successfully!');
    } catch (err) {
      handleAlert(`Error changing bonus type, please try again.`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Add Bonus Type</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Type</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            defaultValue={defaultBonusType}
            value={changedType}
            onChange={(e) => setChangedType(e.target.value)}
            label="Role"
          >
            {bonusTypes.map((bonusType) => (
              <MenuItem key={bonusType.id} value={bonusType.id}>
                {bonusType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Bonus Category</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={changedCategory}
            onChange={(e) => setChangedCategory(e.target.value)}
            label="Role"
          >
            <MenuItem value="spor">Spor</MenuItem>
            <MenuItem value="casino">Casino</MenuItem>
            <MenuItem value="diger">Diger</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Bonus Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={changedName}
          onChange={(e) => setChangedName(e.target.value)}
        />
        <TextField
          label="Bonus Definition ID"
          variant="outlined"
          margin="normal"
          fullWidth
          value={changedDefId}
          onChange={(e) => setChangedDefId(e.target.value)}
        />
        <TextField
          label="Image URL"
          variant="outlined"
          margin="normal"
          fullWidth
          value={changedImageURL}
          onChange={(e) => setChangedImageURL(e.target.value)}
        />
        <TextField
          label="Bonus Note"
          variant="outlined"
          multiline
          rows={4}
          sx={{
            '& .MuiInputBase-root': {
              height: 'auto',
            },
          }}
          margin="normal"
          fullWidth
          value={changedNote}
          onChange={(e) => setChangedNote(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default BonusTypeChangeForm;
