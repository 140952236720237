import { useState } from 'react';
import api from '../../lib/axios';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import OtpForm from '../../components/2faForm';
import { tokens } from '../../theme';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { handleLogin } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [is2faSecret, setIs2faSecret] = useState(Boolean);
  const [isOpenOTP, setIsOpenOtp] = useState(false);
  const [employeeUsername, setEmployeeUsername] = useState('');
  const [QRCodeURL, setQRCodeURL] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/login', {
        username,
        password,
      });
      if (response.data.role) {
        handleLogin(response.data);
      } else {
        handle2fa(
          response.data.username,
          response.data.have2faSecret,
          response.data.tempToken
        );
      }
    } catch (err) {
      setError(`${err.response.data.message}`);
    }
  };

  const handle2fa = async (username, have2faSecret, employeeTempToken) => {
    setEmployeeUsername(username);
    setTempToken(employeeTempToken);
    setIsOpenOtp(true);
    if (have2faSecret) {
      setIs2faSecret(true);
    } else {
      setIs2faSecret(false);
      const response = await api.post('/api/auth/generate-2fa-secret', {
        username,
      });
      setQRCodeURL(response.data.qrCode);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Typography variant="h4">Login</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            marginTop: '16px',
            border: `1px solid #727681`,
          }}
          fullWidth
        >
          Login
        </Button>
      </form>
      {isOpenOTP && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '20rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <OtpForm
            is2faSecret={is2faSecret}
            closeOtpWindow={setIsOpenOtp}
            employeeUsername={employeeUsername}
            qrCodeURL={QRCodeURL}
            employeeTempToken={tempToken}
          />
        </Box>
      )}
    </Box>
  );
};

export default Login;
